<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3 pt-3">
        A TLC plate is spotted with 2 different samples and developed. The results showed that
        Sample 1 separated into four visible spots while Sample 2 only yielded one spot. Which of
        the following statements is correct based on the information provided above?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q6',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {text: 'The compounds in Samples 1 and 2 are all different.', value: 'option1'},
        {
          text: 'The compound in Sample 2 is the same as one of the four in Sample 1.',
          value: 'option2',
        },
        {text: 'Sample 2 contains at least one compound', value: 'option3'},
        {text: 'Sample 2 contains a single compound', value: 'option4'},
      ],
    };
  },
};
</script>
